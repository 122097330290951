import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import HighlightsList from "../components/highlightsList"
import { WPContext } from "../contexts/wpContext"
import { Helmet } from "react-helmet"
import favicon64 from "../images/favicon64.png"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import social from "../images/social.png"

const Content = styled.div`
  width: calc(100% - 460px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 60px 0 0 230px;

  @media (max-width: 1200px) {
    width: calc(100% - 230px);
    margin: 60px 0 0 0;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
  }
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  margin: 0;
  font-weight: 400;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const GalleryArticleContainer = styled(Link)`
  width: 600px;
  margin: 50px 0 0 0;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    margin: 30px 0 0 0;
  }
`

const GalleryArticleTitle = styled.h4`
  font-size: 32px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const GalleryArticleImg = styled.img`
  width: 100%;
`

const GalleryArticleCredit = styled.p`
  font-size: 18px;
  font-family: "franklinGothic";
  margin: 10px 0 0 0;
  color: #294973;
  text-align: end;

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const Gallery = () => {
  const { galleryArticles } = useContext(WPContext)
  const [articles, setArticles] = useState([])

  useEffect(() => {
    let tempDiv = document.createElement("DIV")

    let galleryPosts = galleryArticles.map(post => {
      tempDiv.innerHTML = post.node.title
      post.node.title = tempDiv.innerText
      return post
    })

    setArticles(galleryPosts)
  }, [])

  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${favicon16}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${favicon32}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "64x64",
            href: `${favicon64}`,
          },
        ]}
      >
        <title>Gallery - The Cannon</title>
        <meta property="og:title" content={`The Cannon`} />
        <meta
          property="og:description"
          content="The Cannon is the official (serious) newspaper of the University of Toronto Engineering Society. Established in 1978, it serves the undergraduate students of the Faculty of Applied Science and Engineering."
        />
        <meta property="og:image" content={social} />
        <meta property="og:image:url" content={social} />
      </Helmet>
      <Content>
        <Title>GALLERY</Title>
        {articles &&
          articles.map((post, i) => {
            return (
              <GalleryArticleContainer
                key={`galArticle-${post.node.slug}`}
                to={`/articles/${post.node.slug}`}
              >
                <GalleryArticleTitle>{post.node.title}</GalleryArticleTitle>
                <GalleryArticleImg
                  src={post.node.featured_media.source_url}
                ></GalleryArticleImg>
              </GalleryArticleContainer>
            )
          })}
      </Content>
    </>
  )
}

export default Gallery
